import React, { useEffect } from "react";
import localforage from "localforage";
import { Assets } from "../../constants/images";

const width = 200;

const AvatarImage = ({
  name,
  src,
  placeholder,
  size,
  className = "",
  isSVG,
}) => {
  const [image, setImage] = React.useState(src);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem("forageCacheCleaned", "true");
    });

    const isforageCacheCleaned = sessionStorage.getItem("forageCacheCleaned");

    if (!isforageCacheCleaned) {
      localforage.clear();
      sessionStorage.setItem("forageCacheCleaned", "true");
    }

    localforage
      .getItem(src)
      .then((cachedImage) => {
        if (cachedImage) {
          setImage(URL.createObjectURL(cachedImage));
        } else {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = src;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const aspectRatio = img.height / img.width;
            canvas.width = width;
            canvas.height = width * aspectRatio;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
              localforage.setItem(src, blob);
              setImage(URL.createObjectURL(blob));
            }, "image/jpeg");
          };
        }
      })
      .catch((err) => {
        console.error(err);
        setImage(src);
      });
  }, [src]);

  const getInitials = () => {
    if (name?.length > 0) {
      const transformChars = name
        .split(" ")
        .map((char) => char[0])
        .join("");
      return transformChars;
    } else {
      return (
        <img
          src={placeholder || Assets.ProfilePlaceholder}
          className="avatar-img"
        />
      );
    }
  };

  return (
    <div
      key={image}
      className="avatar-wrapper"
      style={{
        height: `${size || 48}px`,
        width: `${size || 48}px`,
      }}
    >
      {isSVG && <img src={src} className={`avatar-img ${className}`} />}
      {!isSVG && src && !src?.includes("/profile-placeholder") ? (
        <img src={image} className={`avatar-img ${className}`} />
      ) : (
        <div className="avatar-characters">{getInitials()}</div>
      )}
    </div>
  );
};

export default AvatarImage;
